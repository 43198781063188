import React, { useState } from 'react';
import TextAsHTML from '../../../components/_base/TextAsHTML';
import { EventPrivateSettingsType } from '../../../lib/context-providers/event-settings-context/event-settings-context';
import KnovioThumbnail from './KnovioThumbnail';
import WelcomeFooter from './WelcomeFooter';
import { EventPrivateDetailsResponseType } from '../../../lib/api';
import useEventCalendar from '../../../hooks/use-event-calendar';
import './WelcomeBlock.scss';
import useEventToken from '../../../hooks/use-event-token';
import { useTemplateProcessor } from '../../../hooks/use-template-processor';
import { useCurrentUserInfo } from '../../../hooks/api/protected/use-current-user-info';
import SubscriptionCalendarButtons from '../../shared/SubscribeCalendarButton';

const WelcomeBlock = (props: EventPrivateDetailsResponseType & EventPrivateSettingsType): JSX.Element => {
  const {
    name,
    eventId,
    welcomeLabel,
    privateDescription,
    welcomeKnovioEnabled,
    welcomeKnovioEmbedId,
    calendarButtonEnabled,
    eventTitleForPassport,
    calendarInvite,
  } = props;

  const token = useEventToken(eventId);
  const { data: currentUserInfo } = useCurrentUserInfo();
  const { fetchOutlookCalendarForEvent, fetchGoogleCalendarForEvent, unSubscribeToCalendarEvent } = useEventCalendar();
  const renderPrivateDescription = useTemplateProcessor();
  const [attendeeSubscribed, setAttendeeSubscribed] = useState(props.attendeeSubscribedToEvent);

  const updateAttendeeSubscribed = (subscribed: boolean): void => {
    setAttendeeSubscribed(subscribed);
  };

  const getEventTitle = (customTitle: string | undefined, defaultTitle: string): string => {
    return customTitle ? customTitle : defaultTitle;
  };
  const fileName = `${getEventTitle(eventTitleForPassport, name)}.ics`;

  const blockClassName = 'overflow-hidden shadow-gray bg-white mx-auto md:w-3/4 mt-12 max-h-full ' +
    'h-full w-100 mx-0 min-h-24rem flex flex-col justify-between';
  const className = 'editable-welcome';
  const classNameWelcomeWrapper = `${className}--wrapper`;
  const classNameWelcomeDescription = `${className}--description`;
  const classNameWelcomeTitle = `${className}--title`;
  const classNameWelcomeSubcribeTitle = `${className}--subscribe-title`;

  return (
    <div className={`${classNameWelcomeWrapper} ${blockClassName}`}>
      <div>
        <div className='flex justify-between'>
          <h1 className={`${classNameWelcomeTitle} font-size-40px`}>{welcomeLabel}</h1>
          {calendarButtonEnabled &&
            <SubscriptionCalendarButtons
              eventId={eventId.toString()}
              name={name}
              attendeeSubscribed={attendeeSubscribed}
              calendarInvite={calendarInvite}
              token={token}
              fileName={fileName}
              data={currentUserInfo}
              eventTitleForPassport={eventTitleForPassport}
              className={classNameWelcomeSubcribeTitle}
              downloadIcsFile={() => fetchOutlookCalendarForEvent(eventId.toString(), fileName, token)}
              openGoogleCalendar={() => fetchGoogleCalendarForEvent(eventId.toString(), false, token)}
              unSubscribeToCalendar={(resgistrationId: string) => unSubscribeToCalendarEvent(resgistrationId)}
              subscribeToEventLabel='Subscribe to Event'
              unSubscribeToEventLabel='Subscribed to Event'
              isSubscribeToEvent
              updateSubscribe={updateAttendeeSubscribed}
            />
          }
        </div>
        <hr />
        {welcomeKnovioEnabled && welcomeKnovioEmbedId && <KnovioThumbnail welcomeKnovioEmbedId={welcomeKnovioEmbedId} />}
        <div className={`${classNameWelcomeDescription} truncate-advanced-15`}>
          <TextAsHTML formattedText={privateDescription ? renderPrivateDescription(privateDescription) : ''} />
        </div>
      </div>
      <WelcomeFooter {...props} />
    </div>
  );
};

export default WelcomeBlock;
